$navbar-height: 60px;
$navbar-border-radius: none;
$navbar-default-link-color: #b4b4b4;
$navbar-default-link-hover-color: #fff;
$navbar-default-link-hover-bg: #18aa86;
$navbar-default-link-active-color: #fff;
$navbar-default-link-active-bg: #18aa86;
$navbar-default-brand-color: #fff;
$navbar-default-brand-hover-color: #fff;
$dropdown-link-color: #b4b4b4;
$dropdown-link-active-color: #fff;
$dropdown-link-active-bg: #18aa86;
$dropdown-link-hover-bg: #18aa86;
$dropdown-link-hover-color: #fff;

$btn-primary-bg: #0d509f;

$grid-float-breakpoint: 1000px;

@import '../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap';

.navbar-default {
  font-family: "Open Sans Condensed", sans-serif;
  background: url('../../images/background-dark.jpg');
  font-size: 14pt;
  font-weight: 300;
  .navbar-brand {
    h1 {
      font-size: 14pt;
      margin: 0;
      padding: 0;
      font-weight: 700;
    }
  }
  .dropdown-menu {
    background: url('../../images/background-dark.jpg');
    font-size: 13pt;
    box-shadow: none;
    border-radius: 0;
    border: none;
  }
}
/**
 * Content
 */
.container-fluid:not(.homepage-posts) {
  margin-top: 59px;
  background: url('../../images/noise-background.jpg');
  .carousel {
    width: 100%;
  }
}
.container-fluid.homepage-posts {
  background: url('../../images/noise-background.jpg');
  box-shadow: inset 0 0 10px 0 rgba(0,0,0,.3);
  .row:last-child {
    margin: 25px 0 30px 0;
  }
}

.container.content.sub, .container-fluid.slider {
  margin-top: 59px;
}

.container.content, .container-fluid.content {
  &[role="home"] {
    margin-top: 0;
    .col-sm-12  {
      .row:first-child {
        a {
          &:last-child {
            .homepage-box {
              border-right: none;
            }
          }
        }
      }
    }
  }
  .col-sm-4.homepage-box {
    height: 275px;
    vertical-align: middle;
    text-align: center;
    font-family: "Open Sans Condensed", sans-serif;
    font-size: 1.60em;
    font-weight: 300;
    // border-right: 3px double #c0c0c0;
    color: #7d6d6d;
    padding-top: 25px;
    transition: box-shadow ease .6s;
    &:hover {
      box-shadow: 0 0 10px 5px rgba(0,0,0,.1);
    }
    svg {
      width: 70px;
      height: 70px;
      path, g, polygon {
        fill: #c0c0c0;
      }
    }
    h2 {
      font-weight: 700;
      font-size: 1.15em;
      color: #18aa86;
      text-transform: uppercase;
      margin-bottom: 15px !important;
    }
  }
  h2 {
    font-family: "Open Sans Condensed", sans-serif;
    font-weight: 700;
    font-size: 1.7em;
    color: #18aa86;
    text-transform: uppercase;
    margin-bottom: 15px !important;
  }
  h3 {
    font-family: "Open Sans Condensed", sans-serif;
    font-weight: 700;
    font-size: 1.5em;
    color: #18aa86;
    text-transform: uppercase;
    margin-top: 0 !important;
    margin-bottom: 15px !important;
    a {
      color: inherit;
    }
  }
  .col-sm-4, .col-sm-8 {
    color: #3c3b3b;
    img {
      padding: 3px;
      border: 1px solid #ccc;
    }
    p {
      color: #7d6d6d;
      font-weight: normal;
      margin-top: 25px;
      strong {
        color: #3c3b3b;
      }
    }
  }
  .row {
    margin: 25px 0;
  }
  .thumbnail {
    position: relative;
    transition: box-shadow ease .6s;
    padding: 10px;
    &:hover {
      box-shadow: 0 0 10px 5px rgba(0,0,0,.1);
    }
    img {
      display: inline-block;
      padding: 0;
      border: none;
      vertical-align: top;
    }
    .caption {
      display: inline-block;
      vertical-align: top;
    }
    .btn {
      position: absolute;
      bottom: 10px;
      left: 10px;
    }
  }

  h2.title {
    position: relative;
    overflow: hidden;
    width: 100%;
    &:after {
      content: " ";
      position: absolute;
      width: inherit;
      height: 21px;
      margin: 2px 0 0 10px;
      background: url('../../images/header-title-background.png') repeat-x left 4px;
    }
  }

  .btn-requests {
    display: table;
    margin: 0 auto;
    font-family: "Open Sans Condensed", sans-serif;
    width: 300px;
    height: 65px;
    padding: 0;
    line-height: 65px;
    vertical-align: baseline;
    font-size: 18pt;
    font-weight: 700;
  }
  h3.requests {
    width: 100%;
    text-align: center;
  }
  .related-pages {
    div {
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(0,0,0,.4);
      h3 {
        color: #000;
        font-weight: 700;
        font-size: 14pt;
      }
    }
  }
  .posts {
    .thumbnail {
      position: relative;
      min-height: 225px;
      vertical-align: top;
      padding: 10px;
      .image {
        width: 29%;
        display: inline-block;
        vertical-align: top;
        img {
          width: 100%;
          vertical-align: baseline;
        }
      }
      .caption {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        h3 {
          margin-top: 0 !important;
        }
      }
      .image+.caption {
        width: 70%;
      }
      .btn {
        position: absolute;
        bottom: 10px;
        left: 10px;
      }
    }
  }
  .map {
    height: 300px;
  }
}

footer {
  height: 100%;
  min-height: 150px;
  background: url('../../images/noise-background.jpg');
  box-shadow: inset 0px 5px 8px -6px rgba(0,0,0,0.4);
  .title {
    position: relative;
    overflow: hidden;
    width: 100%;
    font-size: 12pt;
    font-weight: 700;
    text-transform: uppercase;
    &:after {
      content: " ";
      position: absolute;
      width: inherit;
      height: 21px;
      margin: 0 0 0 10px;
      background: url('../../images/header-title-background.png') repeat-x left 4px;
    }
  }
}

#mailchimp-modal {
  .btn-primary {
    width: 30%;
    height: 50px;
  }
  .input-group {
    width: 100%;
    margin-bottom: 15px;
    .form-control {
      width: 50%;
      &:first-child {
        border-radius: 5px 0 0 5px;
      }
      &:last-child {
        border-radius: 0 5px 5px 0;
      }
    }
  }
}

.socials {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  list-style-type: none;
  width: 200px;
  height: auto;
  margin: 0;
  padding: 0;
  a {
    color: #fff;
    font-size: 18pt;
    &:hover {
      text-decoration: none;
    }
    li {
      width: 200px;
      height: 50px;
      line-height: 50px;
      padding: 0 15px;
      margin-bottom: 10px;
      margin-left: -150px;
      transition: margin-left ease .3s;
      &:hover {
        margin-left: 0;
      }
      .fa {
        width: 30px;
        height: 50px;
        line-height: 50px;
        float: right;
        text-align: center;
      }
    }
    &:first-child {
      li {
        background: #3B5998;
      }
    }
    &:last-child {
      li {
        background: #da4300;
      }
    }
  }
}

/**
 * Dropdown hover
 */
.sidebar-nav {
    padding: 9px 0;
}

.dropdown-menu .sub-menu {
    left: 100%;
    position: absolute;
    top: 0;
    visibility: hidden;
    margin-top: -1px;
}

.dropdown-menu li:hover .sub-menu {
    visibility: visible;
}

.dropdown:hover .dropdown-menu {
    display: block;
}

.nav-tabs .dropdown-menu, .nav-pills .dropdown-menu, .navbar .dropdown-menu {
    margin-top: 0;
}

.navbar .sub-menu:before {
    border-bottom: 7px solid transparent;
    border-left: none;
    border-right: 7px solid rgba(0, 0, 0, 0.2);
    border-top: 7px solid transparent;
    left: -7px;
    top: 10px;
}
.navbar .sub-menu:after {
    border-top: 6px solid transparent;
    border-left: none;
    border-right: 6px solid #fff;
    border-bottom: 6px solid transparent;
    top: 11px;
    left: -6px;
}

@media (max-width: $screen-sm-max) {
  .posts .thumbnail {
    vertical-align: top;
    height: auto !important;
  }
  .posts .thumbnail .caption {
    margin-bottom: 50px;
  }
  .posts .thumbnail .caption .btn {
    left: 50% !important;
    transform: translateX(-50%);
  }

  .container.content.boxes .col-sm-4 {
    margin-bottom: 25px;
  }

  .slider .container .row .col-sm-12 {
    position: relative;
    height: 250px;
  }
  .slider .container .row .col-sm-12 img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .popup form {
    width: 100%;
    height: 100%;
    max-height: 450px;
  }
  .popup .close {
    top: 0 !important;
    right: 0 !important;
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .homepage-box {
    height: 325px !important;
  }
}
